const state = {
  shopListLoading: false,
  shopHasBeenDeletedShopId: null,
  apiHasBeenDeletedApiId: null,
  api: {},
  apiChangeCounter: 0,
  productViewFilter: [],
  productViewShopSelected: null,
  productViewMongoSort: { _api53_updatedAt: -1 },
  productViewMongoSortModel: 'updateAtDesc',
}

const getters = {
  shopListLoading(state, getters, rootState) {
    return state.shopListLoading;
  },
  shopHasBeenDeletedShopId(state, getters, rootState) {
    return state.shopHasBeenDeletedShopId;
  },
  apiHasBeenDeletedApiId(state, getters, rootState) {
    return state.apiHasBeenDeletedApiId;
  },
  api(state, getters, rootState) {
    return state.api;
  },
  apiChangeCounter(state, getters, rootState) {
    return state.apiChangeCounter;
  },
  productViewFilter(state, getters, rootState) {
    return state.productViewFilter;
  },
  productViewShopSelected(state, getters, rootState) {
    return state.productViewShopSelected;
  },
  productViewMongoSort(state, getters, rootState) {
    return state.productViewMongoSort;
  },
  productViewMongoSortModel(state, getters, rootState) {
    return state.productViewMongoSortModel;
  },
}

const mutations = {
  setShopListLoading(state, value) {
    state.shopListLoading = value
  },
  setShopHasBeenDeletedShopId(state, value) {
    state.shopHasBeenDeletedShopId = value
  },
  setApiHasBeenDeletedApiId(state, value) {
    state.apiHasBeenDeletedApiId = value
  },
  setApi(state, value) {
    state.api = value
  },
  setApiChangeCounter(state, value) {
    state.apiChangeCounter = value
  },
  setProductViewFilter(state, value) {
    state.productViewFilter = value
  },
  setProductViewShopSelected(state, value) {
    state.productViewShopSelected = value
  },
  setProductViewMongoSort(state, value) {
    state.productViewMongoSort = value
  },
  setProductViewMongoSortModel(state, value) {
    state.productViewMongoSortModel = value
  },
}



const misc = {
  namespaced: true,
  state,
  mutations,
  getters
}

export default misc;
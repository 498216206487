const state = {
  loginSuccess: false,
  apiName: '',
  password: '',
  customCsv: '',
  defaultCsv: '',
}

const getters = {
  loginSuccess(state, getters, rootState) {
    return state.loginSuccess;
  },
  apiName(state, getters, rootState) {
    return state.apiName;
  },
  password(state, getters, rootState) {
    return state.password;
  },
  customCsv(state, getters, rootState) {
    return state.customCsv;
  },
  defaultCsv(state, getters, rootState) {
    return state.defaultCsv;
  },
}

const mutations = {
  setLoginSuccess(state, value) {
    state.loginSuccess = value
  },
  setApiName(state, value) {
    state.apiName = value
  },
  setPassword(state, value) {
    state.password = value
  },
  setCustomCsv(state, value) {
    state.customCsv = value
  },
  setDefaultCsv(state, value) {
    state.defaultCsv = value
  },
}



const csvDownload = {
  namespaced: true,
  state,
  mutations,
  getters
}

export default csvDownload;
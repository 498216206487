const state = {
  module1Value: 'Module 1'
}

const getters = {
  module1ValueWithHello(state, getters, rootState) {
    return 'Hello, ' + state.module1Value;
  }
}
const mutations = {
  setModule1Value(state, value) {
    state.module1Value = value;
  }
}

const actions = {
  setModule1Value(context, value) {
    context.commit('setModule1Value', value);
  }
}


const module1 = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default module1;
<template>

  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `${appName}` }}</template>
    <template v-slot:description="{ content }">{{ content }}</template>
  </metainfo>  

  <div class="bg-gray-100">
    <Header />
    <router-view/>
    <Footer />
    <CookieConsent />





  </div>
  
  
</template>

<script>
import { provide, defineAsyncComponent, computed } from 'vue'
const Header = defineAsyncComponent(() =>  import('@/components/Header.vue'));
const Footer = defineAsyncComponent(() => import('@/components/Footer.vue'));
const CookieConsent = defineAsyncComponent(() => import('@/components/CookieConsent.vue'));
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { Auth } from 'aws-amplify'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    CookieConsent,
  }, 
  setup() {

    const { tm } = useI18n()
    const store = useStore()

    // Global Vars
    const globalVars = {
      appName: 'Api53',
      appUrl: process.env.VUE_APP_URL,
      social: {
        twitter: {
          acc: '@api53',
          uri: 'https://twitter.com/api53com'          
        },
      },
      googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      publicApiEndpoint: process.env.VUE_APP_PUBLIC_API_ENDPOINT,
      footerSubscribeUrl: process.env.VUE_APP_FOOTER_SUBSCRIBE_URL,
      contactPageSubmitUrl: process.env.VUE_APP_CONTACT_PAGE_SUBMIT_URL,
      env: process.env.VUE_APP_ENV,
      stripe: {
        productId: process.env.VUE_APP_STRIPE_PRODICT_ID,
        priceId: {
          sku500: {
            monthly: process.env.VUE_APP_STRIPE_PRICE_ID_500_MONTHLY,
            annually: process.env.VUE_APP_STRIPE_PRICE_ID_500_ANNUALLY
          },
          sku1500: {
            monthly: process.env.VUE_APP_STRIPE_PRICE_ID_1500_MONTHLY,
            annually: process.env.VUE_APP_STRIPE_PRICE_ID_1500_ANNUALLY
          },
          sku3000: {
            monthly: process.env.VUE_APP_STRIPE_PRICE_ID_3000_MONTHLY,
            annually: process.env.VUE_APP_STRIPE_PRICE_ID_3000_ANNUALLY
          },
          sku8000: {
            monthly: process.env.VUE_APP_STRIPE_PRICE_ID_8000_MONTHLY,
            annually: process.env.VUE_APP_STRIPE_PRICE_ID_8000_ANNUALLY
          },
          sku20000: {
            monthly: process.env.VUE_APP_STRIPE_PRICE_ID_20000_MONTHLY,
            annually: process.env.VUE_APP_STRIPE_PRICE_ID_20000_ANNUALLY
          },
          sku50000: {
            monthly: process.env.VUE_APP_STRIPE_PRICE_ID_50000_MONTHLY,
            annually: process.env.VUE_APP_STRIPE_PRICE_ID_50000_ANNUALLY
          },
        }
      }
    }
    provide('globalVars', globalVars)
    let appName = globalVars.appName

    // Auth 
    let loggedIn = computed(() => store.getters['auth/loggedIn'])

    if (!loggedIn.value) {
      (async () => {
        const authData = await Auth.currentAuthenticatedUser({ bypassCache: false })
        // console.log(authData)
        if (authData) {
          store.commit('auth/setLoggedIn', true)
          store.commit('auth/setEmail', authData.attributes.email)
          store.commit('auth/setAttributes', authData.attributes)
        }
      })() 
      if (process.env.VUE_APP_ENV !== "production") console.log('fetching cognito')
    }

    // Vue Meta
    const { meta } = useMeta({
      title: tm('seo.title'),
      htmlAttrs: { lang: 'en', amp: false },
      description: tm('seo.description'),
      script: [],
    })

    // const store = useStore()
    // onMounted(() => { 
      
    //   const vueMetaScriptVuex = store.getters['vueMeta/script']

    //   vueMetaScriptVuex.map((item) => {
    //     // console.log(item)
    //     meta.script.push(item)
    //   })
    //   // useMeta(meta)
    // })


    // store.watch((state, getters) => store.getters['vueMeta/counter'], () => {
    //   console.log('value changes detected via vuex watch');
    //   const vueMetaScriptVuex = store.getters['vueMeta/script']

    //   vueMetaScriptVuex.map((item) => {
    //     console.log(item)
    //     meta.script.push(item)
    //   })
    //   console.log(meta.script)
    //   const metadata = useActiveMeta()
    // })

    // watch(() => store.getters['vueMeta/counter'], function() {
    //   console.log('value changes detected 1');
    //   const vueMetaScriptVuex = store.getters['vueMeta/script']

    //   vueMetaScriptVuex.map((item) => {
    //     console.log(item)
    //     meta.script.push(item)
    //   })
    //   useMeta(meta)      
    // });  

    // Rich snippet Company/Logo 
    let richLogo = {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      "url": `${globalVars.appUrl}`,
      "logo": `${globalVars.appUrl}/static/images/api53-logo-transparent-727-727.png`
    }
    
    const richLogoMetaScript = {
      type: 'application/ld+json',
      json: richLogo
    }

    meta.script.push(richLogoMetaScript)


    // const scriptGoolgeAnalytics = { src: 'https://www.googletagmanager.com/gtag/js?id=UA-185400825-1', async: true, head: true }
    // if (globalVars.env === "production") meta.script.push(scriptGoolgeAnalytics)
    
    //cdn.iubenda.com/cs/stable/iubenda_cs.js
    // const scriptIubenda = { src: '//cdn.iubenda.com/cs/stable/iubenda_cs.js', async: true, head: true, charset: 'UTF-8' }
    // if (globalVars.env === "production") meta.script.push(scriptIubenda) 

    // const scriptHelpspace = { 
    //   src: 'https://cdn.helpspace.com/widget/widget-v1.js',
    //   id: 'helpspace-widget-script',
    //   dataAutoInit: true,
    //   dataToken: 'RrrivaxlzZZOLxJ6OZLy4RDZ9gJIM5m70VeamtzW',
    //   dataClientId: 'fc11b3da3ada4af6a62bdf085f5cfd2f',
    //   dataWidgetId: 'bae8993e-ade1-4579-89e1-2b7fd474ac8f',
    //   async: true, 
    //   head: true 
    // }
    // if (globalVars.env === "production") meta.script.push(scriptHelpspace)


    return {
      appName
    }

  }
}
</script>


<style scoped>
.cookie-consent {
  background-color: yellow;
};

.cookie-consent-message {
  color: green;
}
</style>
import { createStore } from 'vuex'

import module1 from './modules/module1';
import vueMeta from './modules/vueMeta';
import pricing from './modules/pricing';
import cookieConsent from './modules/cookieConsent';
import auth from './modules/auth';
import misc from './modules/misc';
import csvDownload from './modules/csvDownload';

export default createStore({
  modules: {
    module1,
    vueMeta,
    pricing,
    cookieConsent,
    auth,
    misc,
    csvDownload,
  }
})
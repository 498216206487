const state = {
  script: [],
}

const getters = {
  script(state, getters, rootState) {
    return state.script;
  },
  counter(state, getters, rootState) {
    return state.counter;
  },
}

const mutations = {
  setScript(state, value) {
    state.script.push(value);
    state.counter++;
  }
}



const vueMeta = {
  namespaced: true,
  state,
  mutations,
  getters
}

export default vueMeta;
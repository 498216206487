export const lang = {
  msg: {
    currencySymbol: "$",
    newsletter: 'subscribe to our newsletter',
    newsletterText: 'The latest product updates, articles, sent to your inbox.',
    allRights: 'All rights reserved.',
    company: 'Company',
    legal: 'Legal',
    accept: 'Accept',
    reject: 'Reject',
    thankYou: 'Thank you',
    notice: 'Notice',
    format: 'Format',
    free: 'Free',
    paid: 'Paid',
    yes: 'Yes',
    no: 'No',
    address: 'Address',
    previous: 'Previous',
    welcomeToApi53: 'Welcome to Api53',
    reload: 'Reload',
    note: 'Note',
    next: 'Next',
    connected: 'Connected',
    comingSoon: 'Coming soon',
    disconnected: 'Disconnected',
    scale: 'Scale',
    nextStep: 'Next step',
    footerDescriptionShort: 'Api53 provides eCommerce solutions to automate product catalog import and export. You can easily import or export your product catalog from a CSV file or REST API.',
    footerDescriptionLong: 'Api53 provides eCommerce solutions to automate product catalog import and export. You can easily import or export your product catalog from a CSV file or REST API. You have many filters and options available that fully support your workflow and requirements.',
    closeMenu: 'Close Menu',
    close: 'Close',
    platform: 'Platform',
    details: 'Details',
    openMenu: 'Open Menu',
    aboutUs: 'About Us',
    mission: 'Our Mission',
    existingCustomer: 'Existing customer',
    signUp: 'Sign up',
    login: 'Log in',
    description: 'Description',
    error404: '404 ERROR',
    confirm: 'Confirm',
    type: 'Type',
    quotas: 'Quotas',
    page: 'Page',
    option: 'Option',
    visitTwitter: 'Visitr Twitter',
    goToPage: 'Go to page',
    getInTouch: 'Get in touch',
    subscribe: 'Subscribe',
    pricePerMonth: 'Price per month',
    annually: 'Annually',
    monthly: 'Monthly',
    perMonth: 'per month',
    perWeek: 'per week',
    plan: 'plan',
    refresh: 'Refresh',
    saveChanges: 'Save changes',
    price: 'Price',
    method: 'Method',
    include: 'Include',
    exclude: 'Exclude',
    quantity: 'Quantity',
    feature: 'Feature',
    perk: 'Perk',
    text: 'Text',
    attributes: 'Attributes',
    attribute: 'Attribute',
    endpoint: 'Endpoint',
    status: 'Status',
    available: 'Available',
    active: 'Active',
    enabled: 'Enabled',
    disabled: 'Disabled',
    inactive: 'Inactive',
    needHelp: 'Need Help?',
    requestCallback: 'Request Callback',
    productAbbrevation: 'Prod.', // Product Abbrevation
    featureComparison: 'Feature comparison',
    perkComparison: 'Perk comparison',
    faq: 'Frequently asked questions',
    payPerUse: 'Pay-Per-Use',
    pageNotExist: 'This page does not exist.',
    pageNotFound: 'The page you are looking for could not be found.',
    popularPages: 'Popular pages',
    otherPerks: 'Other perks',
    exploreSolu: 'Exmplore our E-Commect solutions',
    firstName: 'First Name',
    lastName: 'Last Name',
    company: 'Company',
    pleaseNote: 'Please note',
    email: 'eMail',
    emailSubject: 'eMail Subject',
    emailAddress: 'eMail address',
    message: 'Message',
    yourCustomMessage: 'Your custom message here',
    username: 'Username',
    formErrorSupport: 'There has been an error. Please contact our support: support@api53.com',
    formError: 'There has been an error. Please review your input',
    password: 'Password',
    save: 'Save',
    edit: 'Edit',
    sku: 'SKU',
    here: 'here',
    users: 'Users',
    rules: 'Rules',
    view: 'View',
    viewAll: 'View all',
    formSuccess: 'Data saved successfully',
    repPassword: 'Repeat Password',
    dashboard: 'Dashboard',
    logout: 'Logout',
    submit: 'Submit',
    search: 'Search',
    searchTerm: 'Search term',
    searchFor: 'Search for',
    filter: 'Filter',
    name: 'Name',
    optionalField: 'This field is optional',
    remove: 'Remove',
    overview: 'Overview',
    unauthorized: 'You are not authorized to perform this action',
    settings: 'Settings',
    subscription: 'Subscription',
    dangerZone: 'Danger Zone',
    creationDate: 'Creation Date',
    goToHome: 'Go back to home',
    expotCsv: 'Export CSV',
    importCsv: 'Import CSV',
    updateDate: 'Update Date',
    category: 'Category',
    numberProducts: 'Number of products',
    saveUpTo: 'Save up to',
    weCareAboutPrivacyPolicy: 'We care about the protection of your data. Read our',
    abbreviation: {
      hours: 'h'
    },
  },
  links: {
    home: 'Home',
    twitterSupport: 'You are welcome to write to us via DM and describe your problem or feature request.',
    solutions: 'Solutions',
    pricing: 'Pricing',
    pricingh2: 'Simple no-tricks pricing',
    tos: 'Terms & Conditions',
    privacypolicy: 'Privacy Policy',
    cookiePolicy: 'Cookie Policy',
    contact: 'Contact',
    api: 'Api',
    csv: 'CSV',
    shops: 'Shops',
    shop: 'Shop',
    products: 'Products',
    contactH2: 'Do you have any questions? leave us a message!',
    contactDescription: 'You can get in touch with us at any time.',
    docs: 'Documentation',
    docsDescription: 'View our documentation to learn how to integrate our tools with your E-Commerce Store',
    docsSupport: 'We have extensive documentation about our products. Here you will find everything you need.',
    support: 'Support',
    supportH1: 'Support, need any help?',
    supportH2: 'If you have a concern that you cannot solve yourself, please contact us.',
    companyH2: 'Why are we here and how did we start?',
    supportDescription: 'If you have any questions related to Api53, please contact our support.',
    featureRequest: 'Feature Request',
    featureRequestSupport: 'If you have a request for a new feature of our product, please visit our Feature Request page and post your request there.',
    featureRequestDescription: 'If you\'d like to create a feature request, visit our feedback page.',
    docsApi53SetupGuide: 'Api53 Setup Guide',
    docsInstallApi53App: 'Install Api53 App or Extension',
    docsUsingProductApis: 'Using Product CSV APIs'
  },
  alerts: {
    dangerThereWere: 'There were {count} errors with your submission',
    sucessActionComplete: 'The following {count} actions were successful',
    dataSuccessfullySaved: 'Data was saved successfully',
  },
  diverse: {
    emailSubscription: 'Thank you for signing up for our newsletter!',
    emailSubscriptionText: 'We will regularly send you news about eCommerce and new features of our product.',
    checkMailbox: 'please check your mailbox and confirm the newsletter subscription.',
    contactSubmitSuccess: 'Thank you for contacting us',
    contactSubmitSuccessText: 'We will contact you as soon as possible. Please check the links below. Maybe you\'ll find the answer to your question faster there.',
  },
  solutions: {
    csv: {
      name: 'Product CSV/XML',
      description: 'export csv or xml products',
      componentName: 'SolutionCsv',
    },
    api: {
      name: 'Product API',
      description: 'create product api for your customers',
      componentName: 'SolutionApi',
    },
  },
  seo: require('./seo.json'),
  cookieBanner: require('./cookieBanner.json'),
  pages: {
    contact: require('./pages/contact.json'),
    support: require('./pages/support.json'),
    company: require('./pages/company.json'),
    pricing: require('./pages/pricing.json'),
    signUp: require('./pages/signUp.json'),
    appUserSettings: require('./pages/app/userSettings.json'),
    appUserPassword: require('./pages/app/userPassword.json'),
    userSubscription: require('./pages/app/userSubscription.json'),
    appGlobal: require('./pages/app/appGlobal.json'),
    home: require('./pages/home.json'),
  }
}
const state = {
  loggedIn: false,
  email: '',
  attributes: {}
}

const getters = {
  loggedIn(state, getters, rootState) {
    return state.loggedIn;
  },
  email(state, getters, rootState) {
    return state.email;
  },
  attributes(state, getters, rootState) {
    return state.attributes;
  },
}

const mutations = {
  setLoggedIn(state, value) {
    state.loggedIn = value
  },
  setEmail(state, value) {
    state.email = value
  },
  setAttributes(state, value) {
    state.attributes = value
  },
}



const auth = {
  namespaced: true,
  state,
  mutations,
  getters
}

export default auth;
const Home = () => import('../views/Home.vue')
const Login = () => import('../views/Login.vue')
const Logout = () => import('../views/Logout.vue')
const ForgotPassword = () => import('../views/ForgotPassword.vue')
const ForgotPasswordConfirm = () => import('../views/ForgotPasswordConfirm.vue')
const Signup = () => import('../views/Signup.vue')
const SignupConfirm = () => import('../views/SignupConfirm.vue')
const Pricing = () => import('../views/Pricing.vue')
const Contact = () => import('../views/Contact.vue')
const Company = () => import('../views/Company.vue')
const Support = () => import('../views/Support.vue')
const Solution = () => import('../views/Solution.vue')
const Error404 = () => import('../views/Error404.vue')
const EmailSubscriptionSuccess = () => import('../views/EmailSubscriptionSuccess.vue')
const ContactSubmitSuccess = () => import('../views/ContactSubmitSuccess.vue')
const Tos = () => import('../views/Tos.vue')
const PrivacyPolicy = () => import('../views/PrivacyPolicy.vue')
const CookiePolicy = () => import('../views/CookiePolicy.vue')
const SolutionApi = () => import('../views/solutions/Api.vue')
const SolutionCsv = () => import('../views/solutions/Csv.vue')
const CsvDownload = () => import('../views/CsvDownload.vue')

// Docs
const Documentation = () => import('../views/documentation/Index.vue')
const DocsUsingCsvApis = () => import('../views/documentation/UsingCsvApis.vue')
const DocsApi53SetupGuide = () => import('../views/documentation/Api53SetupGuide.vue')
const DocsInstallApi53App = () => import('../views/documentation/InstallApi53App.vue')

// App
const AppDashboard = () => import('../views/app/Dashboard.vue')
const AppShop = () => import('../views/app/Shop.vue')
const AppShopExportEditAttributes = () => import('../views/app/ShopEditAttributes.vue')
const AppProduct = () => import('../views/app/Product.vue')
const AppProductView = () => import('../views/app/ProductView.vue')
const AppShopAdd = () => import('../views/app/ShopAdd.vue')
const AppShopConnect = () => import('../views/app/ShopConnect.vue')
const AppShopConnectOnProduct = () => import('../views/app/ShopConnectOnProduct.vue')
const AppApiUsers = () => import('../views/app/ApiUsers.vue')
const ApiUserView = () => import('../views/app/ApiUserView.vue')
const AppApi = () => import('../views/app/Api.vue')
const AppApiAdd = () => import('../views/app/ApiAdd.vue')
const AppApiView = () => import('../views/app/ApiView.vue')
// const AppApiEmailTemplate = () => import('../views/app/ApiEmailTemplate.vue')
const AppUserSettings = () => import('../views/app/UserSettings.vue')
const AppUserPassword = () => import('../views/app/UserPassword.vue')
const AppUserSubscription = () => import('../views/app/UserSubscription.vue')

// https://www.sitemaps.org/de/protocol.html

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    siteMap: true,
    siteMapLastmod: '2022-08-19',
    siteMapPriority: 0.9,
    siteMapChangefreq: 'monthly',    
    preRender: true
  },
  {
    path: '/documentation/',
    name: 'Documentation',
    component: Documentation,
    siteMap: true,
    siteMapLastmod: '2022-08-19',
    siteMapPriority: 0.7,
    siteMapChangefreq: 'monthly',
    preRender: true    
  },
  {
    path: '/documentation/using-csv-apis/',
    name: 'DocsUsingCsvApis',
    component: DocsUsingCsvApis,
    siteMapLastmod: '2022-08-19',
    siteMapPriority: 0.5,
    siteMapChangefreq: 'monthly',    
    siteMap: true,
    preRender: true    
  },
  {
    path: '/documentation/api53-setup-guide/',
    name: 'DocsApi53SetupGuide',
    component: DocsApi53SetupGuide,
    siteMap: true,
    siteMapLastmod: '2022-09-11',
    siteMapPriority: 0.5,
    siteMapChangefreq: 'monthly',       
    preRender: true    
  },
  {
    path: '/documentation/install-api53-app-or-extension/',
    name: 'DocsInstallApi53App',
    component: DocsInstallApi53App,
    siteMap: true,
    siteMapLastmod: '2022-08-19',
    siteMapPriority: 0.5,
    siteMapChangefreq: 'monthly',     
    preRender: true    
  },
  // {
  //   path: '/solution/api/',
  //   name: 'SolutionApi',
  //   component: SolutionApi,
  //   siteMap: true,
  //   preRender: true
  // },
  // {
  //   path: '/solution/csv/',
  //   name: 'SolutionCsv',
  //   component: SolutionCsv,
  //   siteMap: true,
  //   preRender: true
  // },  
  // {
  //   path: '/solution/',
  //   name: 'Solution',
  //   component: Solution,
  //   siteMap: true,
  //   preRender: true    
  // },
  {
    path: '/login/',
    name: 'Login',
    component: Login,
    siteMap: true,
    siteMapLastmod: '2022-01-30',
    siteMapPriority: 0.1,
    siteMapChangefreq: 'monthly',     
    preRender: true    
  },
  {
    path: '/forgot-password/',
    name: 'ForgotPassword',
    component: ForgotPassword,
    preRender: true
  },
  {
    path: '/forgot-password-confirm/',
    name: 'ForgotPasswordConfirm',
    component: ForgotPasswordConfirm,
  },  
  {
    path: '/signup/',
    name: 'Signup',
    component: Signup,
    siteMap: true,
    siteMapLastmod: '2022-03-31',
    siteMapPriority: 0.5,
    siteMapChangefreq: 'monthly',      
    preRender: true    
  },
  {
    path: '/signup-confirm/',
    name: 'SignupConfirm',
    component: SignupConfirm   
  },  
  {
    path: '/pricing/',
    name: 'Pricing',
    component: Pricing,
    siteMap: true,
    siteMapLastmod: '2022-08-19',
    siteMapPriority: 0.7,
    siteMapChangefreq: 'monthly',     
    preRender: true
  },  
  {
    path: '/company/',
    name: 'Company',
    component: Company,
    siteMap: true,
    siteMapLastmod: '2022-08-19',
    siteMapPriority: 0.5,
    siteMapChangefreq: 'monthly',     
    preRender: true
  },  
  {
    path: '/terms-and-conditions',
    name: 'Tos',
    component: Tos
  },
  {
    path: '/logout/',
    name: 'Logout',
    component: Logout
  },  
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    preRender: false
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: CookiePolicy,
    preRender: false
  },
  {
    path: '/contact/',
    name: 'Contact',
    component: Contact,
    siteMap: true,
    siteMapLastmod: '2022-08-19',
    siteMapPriority: 0.5,
    siteMapChangefreq: 'monthly',         
    preRender: true
  },
  {
    path: '/email-subscription-success/',
    name: 'EmailSubscriptionSuccess',
    component: EmailSubscriptionSuccess,
    siteMap: false,
    preRender: true
  },
  {
    path: '/contact-submit-success/',
    name: 'ContactSubmitSuccess',
    component: ContactSubmitSuccess,
    siteMap: false,
    preRender: true
  },  
  {
    path: '/support/',
    name: 'Support',
    component: Support,
    siteMap: true,
    siteMapLastmod: '2022-08-19',
    siteMapPriority: 0.5,
    siteMapChangefreq: 'monthly',       
    preRender: true    
  },
  {
    path: '/csv-download/:apiId/',
    name: 'CsvDownload',
    component: CsvDownload,
  },
  {
    path: '/app/',
    name: 'AppDashboard',
    component: AppDashboard
  },
  {
    path: '/app/products/',
    name: 'AppProduct',
    component: AppProduct
  },
  {
    path: '/app/products/view/:shopId/:productId',
    name: 'AppProductView',
    component: AppProductView
  },
  {
    path: '/app/api/view/:id',
    name: 'AppApiView',
    component: AppApiView
  },
  // {
  //   path: '/app/api/view/:apiId/email-template/',
  //   name: 'AppApiEmailTemplate',
  //   component: AppApiEmailTemplate
  // },
  
  {
    path: '/app/api-users/',
    name: 'AppApiUsers',
    component: AppApiUsers
  },
  {
    path: '/app/api-users/:userId',
    name: 'ApiUserView',
    component: ApiUserView
  },  
  {
    path: '/app/shop/',
    name: 'AppShop',
    component: AppShop
  },
  {
    path: '/app/shop/import/',
    name: 'AppShopImport',
    component: AppShop
  },
  {
    path: '/app/shop/export/',
    name: 'AppShopExport',
    component: AppShop
  },
  {
    path: '/app/shop/:shopId/edit-attributes/',
    name: 'AppShopExportEditAttributes',
    component: AppShopExportEditAttributes    
  },
  {
    path: '/app/shop/add/',
    name: 'AppShopAdd',
    component: AppShopAdd    
  },
  {
    path: '/app/shop/connect/',
    name: 'AppShopConnect',
    component: AppShopConnect    
  },
  {
    path: '/app/shop/connect-on-product/:shopId',
    name: 'AppShopConnectOnProduct',
    component: AppShopConnectOnProduct    
  },  
  {
    path: '/app/api/',
    name: 'AppApi',
    component: AppApi    
  },
  {
    path: '/app/api/add/',
    name: 'AppApiAdd',
    component: AppApiAdd    
  },  
  {
    path: '/app/user-settings/',
    name: 'AppUserSettings',
    component: AppUserSettings    
  },
  {
    path: '/app/user-password/',
    name: 'AppUserPassword',
    component: AppUserPassword    
  },
  {
    path: '/app/user-subscription/',
    name: 'AppUserSubscription',
    component: AppUserSubscription    
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'Error404',
    component: Error404 
  },  
]


// REQ exports.routes = routes; // DO NOT DELETE - REQUIRED FOR /sitemap/create.js
const state = {
  quantityActive: 0,
  free: true,
  priceEur: 0,
  priceAnnual: 0,
  priceEurBilledMonthly: 0,
  stripe: {},
}

const getters = {
  quantityActive(state, getters, rootState) {
    return state.quantityActive;
  },
  free(state, getters, rootState) {
    return state.free;
  },
  priceEur(state, getters, rootState) {
    return state.priceEur;
  },
  priceAnnual(state, getters, rootState) {
    return state.priceAnnual;
  },
  priceEurBilledMonthly(state, getters, rootState) {
    return state.priceEurBilledMonthly;
  },
  stripe(state, getters, rootState) {
    return state.stripe;
  },  
}

const mutations = {
  setQuantityActive(state, value) {
    state.quantityActive = value
  },
  setFree(state, value) {
    state.free = value
  },
  setPriceEur(state, value) {
    state.priceEur = value
  },
  setPriceAnnual(state, value) {
    state.priceAnnual = value
  },
  setPriceEurBilledMonthly(state, value) {
    state.priceEurBilledMonthly = value
  },
  setStripe(state, value) {
    state.stripe = value
  },  
}



const pricing = {
  namespaced: true,
  state,
  mutations,
  getters
}

export default pricing;
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

let app = createApp(App)

import './assets/tailwind.css'
import VueGtag from "vue-gtag";
import i18n from './i18n'

app.use(i18n)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueGtag, {
    bootstrap: false,
    params: {
      anonymize_ip: true
    }    
  })  
  .use(createMetaManager())

router.isReady().then(() => app.mount('#app'))
